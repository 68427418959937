import { RequireAuth, useIsAuthenticated } from "react-auth-kit";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ChangePasswordPage from "./pages/ChangePasswordPage";
import Dashboard from "./pages/Dashboard";
import Documentation from "./pages/Documentation";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import LoginPage from "./pages/LoginPage";
import ProfilePage from "./pages/ProfilePage";

function App() {
  const PrivateRoute = ({ Component }) => {
    const isAuthenticated = useIsAuthenticated();
    const auth = isAuthenticated();
    return auth ? Component : <Navigate to="/login" />;
  };
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/" element={<PrivateRoute Component={<Dashboard />} />} />
        <Route
          path="/profile"
          element={<PrivateRoute Component={<ProfilePage />} />}
        />
        <Route
          path="/documentation"
          element={<PrivateRoute Component={<Documentation />} />}
        />
        <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
        <Route path="/changepassword" element={<ChangePasswordPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
