import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "user",
  storage,
  whitelist: ["user"],
};

const initialState = {
  user: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setCompanyName: (state, { payload }) => {
      state.user = payload;
    },
  },
  extraReducers: (builder) => {},
});

const persistedReducer = persistReducer(persistConfig, userSlice.reducer);

export const { setCompanyName } = userSlice.actions;

export default persistedReducer;
