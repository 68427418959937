import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT;
const initialState = {
  grafanas: null,
  loading: false,
};

export const getCompanyInfo = createAsyncThunk(
  "profile/getCompanyInfo",
  async ({ token }) => {
    const res = await axios.get(`${BASE_URL}/api/companies/me`, {
      headers: {
        Authorization: token,
      },
    });

    if (res.data.status === "success") {
      return res.data.data;
    }
  }
);

export const grafanaSlice = createSlice({
  name: "grafana",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCompanyInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCompanyInfo.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.grafanas = payload?.company?.dashboard;
    });
    builder.addCase(getCompanyInfo.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {} = grafanaSlice.actions;

export const grafanaReducer = grafanaSlice.reducer;
