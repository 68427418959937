import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT;
const initialState = {
  companyBrief: "",
  companyNews: "",
  companyLogo: "",
  companyIcon: "",
  companyBackground: "",
  primaryColor: "",
  secondaryColor: "",
  thirdColor: "",
  tertiaryColor: "",
  loading: false,
  user: null,
  userEmail: "",
};

export const getCompanyInfo = createAsyncThunk(
  "profile/getCompanyInfo",
  async ({ token }) => {
    const res = await axios.get(`${BASE_URL}/api/companies/me`, {
      headers: {
        Authorization: token,
      },
    });

    if (res.data.status === "success") {
      return res.data.data;
    }
  }
);

export const getUserInfo = createAsyncThunk(
  "profile/getUserInfo",
  async ({ token }) => {
    const res = await axios.get(`${BASE_URL}/api/users/me`, {
      headers: {
        Authorization: token,
      },
    });
    if (res.data.status === "success") {
      return res.data.data;
    }
  }
);

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    updateCompanyBrief: (state, { payload }) => {
      state.companyBrief = payload;
    },
    updateCompanyNews: (state, { payload }) => {
      state.companyNews = payload;
    },
    updatePrimaryColor: (state, { payload }) => {
      state.primaryColor = payload;
    },
    updateSecondaryColor: (state, { payload }) => {
      state.secondaryColor = payload;
    },
    updateThirdColor: (state, { payload }) => {
      state.thirdColor = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCompanyInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCompanyInfo.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.companyBrief = payload?.company?.brief;
      state.companyNews = payload?.company?.news;
      state.companyIcon = payload?.company?.profile?.icon;
      state.companyLogo = payload?.company?.profile?.logo;
      state.companyBackground = payload?.company?.profile?.backgroundImage;
      state.primaryColor = payload?.company?.profile?.primaryColor;
      state.secondaryColor = payload?.company?.profile?.secondaryColor;
      state.thirdColor = payload?.company?.profile?.thirdColor;
      state.user = payload?.company;
    });
    builder.addCase(getCompanyInfo.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getUserInfo.fulfilled, (state, { payload }) => {
      state.userEmail = payload.user.email;
    });
  },
});

export const {
  clearTextsInput,
  updateCompanyBrief,
  updateCompanyNews,
  updatePrimaryColor,
  updateSecondaryColor,
  updateThirdColor,
} = profileSlice.actions;

export const profileReducer = profileSlice.reducer;
