import React, { useEffect } from "react";
import { useAuthHeader } from "react-auth-kit";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { getCompanyInfo } from "../redux/features/GrafanaSlice";

function Dashboard() {
  const dispatch = useDispatch();
  const authState = useAuthHeader();
  const token = authState();
  useEffect(() => {
    dispatch(getCompanyInfo({ token }));
  }, []);
  const grafanas = useSelector((state) => state.grafana.grafanas);
  return (
    <>
      <div className="bg-slate-800 min-h-screen">
        <Navbar />
        <h1 className="text-center text-white my-2">Last 30 days</h1>
        <div className="flex space-x-2 justify-center ">
          <div className="flex flex-col space-y-2">
            <iframe src={grafanas?.newUsers} width="150" height="200"></iframe>
            <iframe
              src={grafanas?.activeUsers}
              width="150"
              height="200"
            ></iframe>
            <iframe
              src={grafanas?.totalUsers}
              width="150"
              height="200"
            ></iframe>
            <iframe
              src={grafanas?.QuestionsAsked}
              width="150"
              height="200"
            ></iframe>
          </div>
          <div className="flex flex-col space-y-2">
            <iframe
              src={grafanas?.newUsersTable}
              width="500"
              height="500"
            ></iframe>
            <div className="flex space-x-2">
              <iframe
                src={grafanas?.tokensSpent}
                width="200"
                height="200"
              ></iframe>
              <iframe
                src={grafanas?.tokensLeft}
                width="290"
                height="200"
              ></iframe>
            </div>
          </div>
          <div className="flex flex-col space-y-2">
            <iframe src={grafanas?.topUsers} width="900" height="400"></iframe>
            <div className="flex space-x-2">
              <iframe
                src={grafanas?.tokensSpentOverTime}
                width="500"
                height="400"
              ></iframe>
              <iframe
                src={grafanas?.topDomains}
                width="390"
                height="420"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Dashboard;
