import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useAuthHeader, useAuthUser, useSignOut } from "react-auth-kit";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function Navbar() {
  const signOut = useSignOut();
  const authState = useAuthHeader();
  const token = authState();
  const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT;
  const [isOpen, setIsOpen] = useState(false);
  const companyName = useSelector((state) => state.user.user);
  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-gray-800">
      <div className="mx-auto px-4 py-3 md:flex md:items-center md:justify-between">
        <div className="flex items-center justify-between">
          <div className="text-white font-bold text-xl">{companyName} Cere</div>
          <button
            className="block md:hidden border border-white rounded py-2 px-3 text-white"
            onClick={toggleNav}
          >
            <svg
              className="h-6 w-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {isOpen ? (
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3.75 3.75H20.25V5.25H3.75V3.75ZM3.75 9.75H20.25V11.25H3.75V9.75ZM3.75 15.75H20.25V17.25H3.75V15.75Z"
                />
              ) : (
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.5 5.25H19.5V6.75H4.5V5.25ZM4.5 10.5H19.5V12H4.5V10.5ZM4.5 15.75H19.5V17.25H4.5V15.75Z"
                />
              )}
            </svg>
          </button>
        </div>

        <div
          className={`${
            isOpen ? "block" : "hidden"
          } md:flex md:items-center md:justify-end mt-3 md:mt-0`}
        >
          <Link to="/">
            <h1 className="block mt-1 md:inline-block md:mt-0 text-white hover:text-gray-400 mr-4">
              Dashboard
            </h1>
          </Link>

          <Link to="/profile">
            <h1 className="block mt-1 md:inline-block md:mt-0 text-white hover:text-gray-400 mr-4">
              Profile
            </h1>
          </Link>
          <Link to="/documentation  ">
            <h1 className="block mt-1 md:inline-block md:mt-0 text-white hover:text-gray-400 mr-4">
              Docs
            </h1>
          </Link>

          <button
            onClick={() => signOut()}
            className="block mt-1 md:inline-block md:mt-0 text-white hover:text-gray-400"
          >
            Logout
          </button>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
