import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CompanyTexts from "../components/CompanyTexts";
import Footer from "../components/Footer";
import ImageUploader from "../components/ImageUploader";
import Navbar from "../components/Navbar";
import ThemeColor from "../components/ThemeColor";
import { getCompanyInfo, getUserInfo } from "../redux/features/ProfileSlice";
import { useAuthHeader } from "react-auth-kit";
function ProfilePage() {
  const user = useSelector((state) => state.profile.user);
  const userEmail = useSelector((state) => state.profile.userEmail);
  const dispatch = useDispatch();
  const authState = useAuthHeader();
  const token = authState();
  useEffect(() => {
    dispatch(getCompanyInfo({ token }));
    dispatch(getUserInfo({ token }));
  }, []);
  return (
    <>
      <div className="bg-slate-800 min-h-screen">
        <Navbar />
        <div className="max-w-7xl mx-auto py-12 sm:px-6 lg:px-8">
          <div className="px-4 py-4 bg-white shadow sm:rounded-lg">
            <div className="flex items-center justify-between">
              <div className="flex-1 min-w-0">
                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                  {user?.name} Cere
                </h2>
                <p className="mt-1 text-sm text-gray-500">{userEmail}</p>
              </div>
              {/* <div className="ml-4 flex-shrink-0">
                <button className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  Edit Profile
                </button>
              </div> */}
            </div>
            <div className="mt-5">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">User</dt>
                  <dd className="mt-1 text-sm text-gray-900">{user?.name}</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Email address
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">{userEmail}</dd>
                </div>

                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    Membership
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 capitalize">
                    {user?.membership}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
        <div className="flex justify-between max-w-7xl mx-auto py-12 sm:px-6 lg:px-8">
          <ImageUploader type="Logo" mustWidth={390} mustHeight={102} />
          <ImageUploader type="Icon" mustWidth={390} mustHeight={102} />
          <ImageUploader type="Background" mustWidth={1080} mustHeight={1213} />
        </div>
        <div className="flex justify-between max-w-7xl mx-auto py-12 sm:px-6 lg:px-8">
          <CompanyTexts title="Company Brief" />
          <CompanyTexts title="Company News" />
          <div className="flex flex-col justify-evenly">
            <ThemeColor title="Primary" />
            <ThemeColor title="Secondary" />
            <ThemeColor title="Third" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ProfilePage;
