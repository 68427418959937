import axios from "axios";
import React, { useState } from "react";
import { useAuthHeader } from "react-auth-kit";

export default function ImageUploader({ type, mustWidth, mustHeight }) {
  const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT;
  const [imageFile, setImageFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [imageSend, setImageSend] = useState();
  const authState = useAuthHeader();
  const token = authState();
  let imageName;
  if (type === "Logo") {
    imageName = "logo";
  } else if (type === "Icon") {
    imageName = "icon";
  } else if (type === "Background") {
    imageName = "background";
  }
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    // if (!file.type.startsWith("image/")) {
    //   setErrorMessage("Invalid file type. Please select an image file.");
    //   setImageFile(null);
    //   return;
    // }
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      const image = new Image();

      image.src = reader.result;

      image.onload = () => {
        const width = image.width;
        const height = image.height;
        if (!(width === mustWidth) || !(height === mustHeight)) {
          setErrorMessage(
            `Image dimensions must be ${mustWidth}px x ${mustHeight}px`
          );
          setImageFile(null);
        } else {
          setErrorMessage(null);
          setImageFile(file);
        }
      };
    };
  };

  const config = {
    headers: {
      Authorization: token,
    },
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append(imageName, imageFile);
    const res = await axios.post(
      `${BASE_URL}/api/companies/${imageName}`,
      formData,
      config
    );
    setImageSend(res.data.status);
  };

  return (
    <div className="flex flex-col items-center justify-between py-3 w-[350px] h-[300px] bg-white rounded-lg ">
      <label className="flex flex-col justify-center items-center h-[250px]">
        <svg
          className="w-8 h-8"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            fillRule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 00-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 10-1.414-1.414L11 10.586V7z"
            clipRule="evenodd"
          />
        </svg>
        <h1 className="text-xl">Drop your {type}</h1>
        <h1>or</h1>

        <input
          type="file"
          onChange={handleFileChange}
          className="w-[250px] h-[100px] border-dotted border-gray-400 border-2 p-2 "
        />
      </label>
      {errorMessage && (
        <p className="text-red-500 text-center mb-2">{errorMessage}</p>
      )}
      {imageSend === "success" && (
        <p className="text-green-500 text-center mb-2">
          Your file has been uploaded
        </p>
      )}
      <button
        onClick={handleSubmit}
        className="w-[100px] bg-slate-400 rounded-lg text-xl font-semibold"
      >
        Upload
      </button>
    </div>
  );
}
