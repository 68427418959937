import { configureStore } from "@reduxjs/toolkit";
import persistStore from "redux-persist/es/persistStore";
import { grafanaReducer } from "./features/GrafanaSlice";
import { profileReducer } from "./features/ProfileSlice";
import persistedReducer from "./features/UserSlice";
export const store = configureStore({
  reducer: {
    user: persistedReducer,
    profile: profileReducer,
    grafana: grafanaReducer,
  },
});

export const persistor = persistStore(store);
