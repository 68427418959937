import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import logo from "../assets/beyazcerelogo.svg";
import { useSignIn } from "react-auth-kit";
import axios from "axios";
import { setCompanyName } from "../redux/features/UserSlice";

function LoginPage() {
  const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT;
  const signIn = useSignIn();
  const auth = useSelector((state) => state.user.auth);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(`${BASE_URL}/api/auth/login`, {
        email: email,
        password: password,
      });

      if (
        signIn({
          token: response.data.access_token,
          tokenType: "Bearer",
          authState: { email: email },
          expiresIn: 120,
        })
      ) {
        dispatch(setCompanyName(response?.data?.userInfo?.company?.name));

        navigate("/");
      }
    } catch (error) {
      setErrorMessage(error?.response?.data?.message);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-slate-900 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div className="flex flex-col items-center ">
          <img src={logo} alt="cere" className="w-[200px]" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-white">
            Sign in to your account
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={onSubmit}>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                value={email}
                onChange={handleEmailChange}
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Email address"
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                value={password}
                onChange={handlePasswordChange}
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Password"
              />
            </div>
          </div>

          <div className="flex justify-between">
            <div className="flex items-center justify-between">
              <div className="text-sm">
                <Link to="/forgotpassword">
                  <h1 className="font-medium text-indigo-600 hover:text-indigo-500">
                    Forgot your password?
                  </h1>
                </Link>
              </div>
            </div>
            {errorMessage && (
              <div className="flex items-center justify-between">
                <div className="text-red-500">
                  <h1>{errorMessage}</h1>
                </div>
              </div>
            )}
          </div>
          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:
              focus:ring-offset-2 focus:ring-indigo-500"
            >
              Sign in
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default LoginPage;
