import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

function Documentation() {
  return (
    <>
      <div className="bg-slate-800 min-h-screen">
        <Navbar />
        <div className="max-w-7xl mx-auto py-12 sm:px-6 lg:px-8">
          <div className="px-4 py-4 bg-white shadow sm:rounded-lg flex flex-col space-y-2">
            <div className="flex flex-col space-y-1">
              <h1 className="text-xl font-semibold">1.Sign in to account</h1>
              <li>
                The application needs two credentials to login to account, email
                and password.
              </li>
              <li>
                After typing correct credentials application navigates to login
                page to dashboard page that is protected.
              </li>
            </div>
            <div className="flex flex-col space-y-1">
              <h1 className="text-xl font-semibold">2.Navigation</h1>

              <li>
                The navigation bar is located at the top of every page and
                contains links to different parts of the website.
              </li>
              <li>
                Clicking on the "Dashboard" button will take you back to the
                Dashboard page.
              </li>
              <li>
                Clicking on the "Profile" button will take you to your profile
                page.
              </li>
              <li>
                Clicking on the "Docs" button will take you to your
                Documentation page.
              </li>
              <li>
                Clicking on the "Logout" button will log you out of your
                account.
              </li>
            </div>
            <div className="flex flex-col space-y-1">
              <h1 className="text-xl font-semibold">3.Dashboard Page</h1>
              <li>
                After login page with correct credentials, Dashboard page
                renders with graphics that show your corporation usage
                information of Cere
              </li>
            </div>
            <div className="flex flex-col space-y-1">
              <h1 className="text-xl font-semibold">4.Profile Page</h1>

              <li>
                Your profile page is where you can manage your company
                information and customize your information for Cere.
              </li>
              <li>
                You can upload your logo, icon, and background images dropping
                or choosing .png or .jpg format.
              </li>
              <li>
                Each images needs to be fit for the image dimensions that are
                written in down below.
              </li>
              <li>
                {" "}
                -Icon : 390px x 102px -Logo: 390px x 102px -Background: 1080px x
                1213px
              </li>
              <li>
                You can customize your company's primary and secondary theme
                colors by writing and and clicking submit button for each
                values.
              </li>
              <li>Theme colors need to be hex format(#FFFFFF)</li>
              <li>
                You can upload your company's brief and news that will be used
                by Cere. These are limited for 3500 character to write.
              </li>
              <li>
                There maybe some characters in the input field of company brief
                or company news. This means that there is brief or news in our
                database to serve them to Cere.
              </li>
              <li>
                You may update them by overwritting and clicking the submit
                button.
              </li>
              <li>
                Clicking delete button means that you want to delete your
                information from our database.
              </li>
              <li>
                You can also edit your company information such as your name,
                email, and membership.
              </li>
              <li>
                To access your profile page, click on the "Profile" button in
                the navigation bar.
              </li>
            </div>
            <div className="flex flex-col space-y-1">
              <h1 className="text-xl font-semibold">5.Access Control</h1>

              <li>
                Some pages on the website are protected and require you to be
                logged in to access them.
              </li>
              <li>
                If you try to access a protected page without being logged in,
                you'll be redirected to the login page.
              </li>
              <li>
                If you try to access a protected page with an unauthorized
                account, you'll be redirected to the Login Page.
              </li>
            </div>
            <div className="flex flex-col space-y-1">
              <h1 className="text-xl font-semibold">6.Log out</h1>
              <li>
                To log out of your account, click on the "Logout" button in the
                navigation bar.
              </li>
              <li>
                This will log you out of your account and redirect you to the
                Login page.
              </li>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Documentation;
