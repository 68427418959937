import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useAuthHeader } from "react-auth-kit";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserInfo,
  updateCompanyBrief,
  updateCompanyNews,
} from "../redux/features/ProfileSlice";

const CompanyTexts = ({ title }) => {
  const authState = useAuthHeader();
  const token = authState();
  const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT;
  const [inputValue, setInputValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { companyBrief, companyNews } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  useEffect(() => {
    if (title === "Company Brief") {
      setInputValue(companyBrief);
    } else if (title === "Company News") {
      setInputValue(companyNews);
    }
  }, [title, companyBrief, companyNews]);

  const handleInputChange = (event) => {
    console.log(event.target.value?.length);

    setInputValue(event.target.value);
    if (event.target.value?.length > 3499) {
      setErrorMessage("Character limit exceeded");
    } else {
      setErrorMessage("");
    }
  };

  const updateCompanyBriefInDB = async () => {
    const res = await axios.post(
      `${BASE_URL}/api/companies`,
      {
        brief: inputValue,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
  };

  const updateCompanyNewsInDB = async () => {
    const res = await axios.post(
      `${BASE_URL}/api/companies`,
      {
        news: inputValue,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (title === "Company Brief") {
      updateCompanyBriefInDB();
      dispatch(updateCompanyBrief(inputValue));
    } else if (title === "Company News") {
      updateCompanyNewsInDB();
      dispatch(updateCompanyNews(inputValue));
    }
  };

  function deleteCompanyText() {
    setInputValue("");
  }

  return (
    <div>
      <h1 className="text-center text-white font-semibold text-xl">{title}</h1>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col items-center w-[500px]"
      >
        <textarea
          className="w-full max-w-md p-2 mb-2 border border-gray-400 rounded-md"
          placeholder="Enter text here"
          value={inputValue}
          onChange={handleInputChange}
          maxLength={3500}
          rows={10}
        />
        {errorMessage && <p className="text-red-500">{errorMessage}</p>}
        <div className="flex space-x-2">
          <button
            onClick={deleteCompanyText}
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
            disabled={inputValue?.length === 0 || errorMessage !== ""}
          >
            Delete
          </button>
          <button
            type="submit"
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
            disabled={inputValue?.length === 0 || errorMessage !== ""}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default CompanyTexts;
