import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import logo from "../assets/beyazcerelogo.svg";
import { useSignIn } from "react-auth-kit";
import axios from "axios";
import { setCompanyName } from "../redux/features/UserSlice";

function ForgotPasswordPage() {
  const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT;

  const [email, setEmail] = useState("");
  const [emailSend, setEmailSend] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(`${BASE_URL}/api/auth/forgotpass`, {
        email: email,
      });

      if (response.data.status === "success") {
        setEmailSend(true);
      }
    } catch (error) {}
  };

  if (emailSend === true) {
    setTimeout(() => {
      navigate("/changepassword");
    }, 3000);
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-slate-900 py-12 px-4 sm:px-6 lg:px-8">
      {!emailSend ? (
        <div className="max-w-md w-full space-y-8">
          <div className="flex flex-col items-center ">
            <img src={logo} alt="cere" className="w-[200px]" />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-white">
              Get recovery code
            </h2>
          </div>
          <form className="mt-8 space-y-6" onSubmit={onSubmit}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={handleEmailChange}
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Email address"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:
              focus:ring-offset-2 focus:ring-indigo-500"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div className="flex flex-col items-center ">
          <img src={logo} alt="cere" className="w-[200px]" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-white">
            Your code send to your email. Please get your covery code.
          </h2>
        </div>
      )}
    </div>
  );
}

export default ForgotPasswordPage;
