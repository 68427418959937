import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAuthHeader } from "react-auth-kit";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCompanyInfo,
  updatePrimaryColor,
  updateSecondaryColor,
  updateThirdColor,
} from "../redux/features/ProfileSlice";

const ThemeColor = ({ title }) => {
  const authState = useAuthHeader();
  const token = authState();
  const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT;
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { primaryColor, secondaryColor, thirdColor } = useSelector(
    (state) => state.profile
  );

  useEffect(() => {
    if (title === "Primary") {
      setInputValue(primaryColor);
    } else if (title === "Secondary") {
      setInputValue(secondaryColor);
    } else if (title === "Third") {
      setInputValue(thirdColor);
    }
  }, [title, primaryColor, secondaryColor, thirdColor]);
  const handleInputChange = (event) => {
    const value = event.target.value;
    if (/^#?[0-9a-fA-F]*$/.test(value)) {
      setInputValue(value.toUpperCase().substring(0, 7));
      setErrorMessage("");
    } else {
      setErrorMessage("Please enter a valid hex code");
    }
  };

  const updatePrimaryColorInDB = async () => {
    const res = await axios.post(
      `${BASE_URL}/api/companies`,
      {
        primaryColor: inputValue,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    console.log(res);
  };

  const updateSecondaryColorInDB = async () => {
    const res = await axios.post(
      `${BASE_URL}/api/companies`,
      {
        secondaryColor: inputValue,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    console.log(res);
  };

  const updateThirdColorInDB = async () => {
    const res = await axios.post(
      `${BASE_URL}/api/companies`,
      {
        thirdColor: inputValue,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    console.log(res);
  };

  const handleSubmit = () => {
    // Send the hex code to the server
    //dispatch(updateCompanyInfo());
    if (title === "Primary") {
      updatePrimaryColorInDB();
      dispatch(updatePrimaryColor(inputValue));
    } else if (title === "Secondary") {
      updateSecondaryColorInDB();
      dispatch(updateSecondaryColor(inputValue));
    } else if (title === "Third") {
      updateThirdColorInDB();
      dispatch(updateThirdColor(inputValue));
    }
  };

  function deleteThemeColor() {
    setInputValue("");
  }

  return (
    <div className="flex flex-col items-center">
      <h1 className="text-center text-white font-semibold text-xl">{title}</h1>
      <div className="flex space-x-2">
        <input
          className="border-2 border-gray-300 p-2 rounded-lg w-[175px] text-center"
          type="text"
          maxLength="7"
          placeholder="#000000"
          value={inputValue}
          onChange={handleInputChange}
        />
        {title === "Primary" && (
          <div
            className="w-10 h-10"
            style={{ backgroundColor: primaryColor }}
          ></div>
        )}
        {title === "Secondary" && (
          <div
            className="w-10 h-10"
            style={{ backgroundColor: secondaryColor }}
          ></div>
        )}
        {title === "Third" && (
          <div
            className="w-10 h-10"
            style={{ backgroundColor: thirdColor }}
          ></div>
        )}
      </div>
      {errorMessage && <p className="text-red-500 text-sm">{errorMessage}</p>}
      <div className="flex space-x-2 mt-2">
        <button
          onClick={deleteThemeColor}
          className="bg-red-500 hover:bg-red-700 text-white font-normal py-2 px-4 rounded"
          disabled={inputValue.length === 0 || errorMessage !== ""}
        >
          Delete
        </button>
        <button
          onClick={handleSubmit}
          className="bg-green-500 hover:bg-green-700 text-white font-normal py-2 px-4 rounded"
          disabled={inputValue.length === 0 || errorMessage !== ""}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default ThemeColor;
